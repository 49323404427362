import * as React from "react"
import Layout from "./components/layout"

// markup
const BlogPage = () => {
  return (
    <Layout title="Blog">
      <h2>BLOG</h2>
    </Layout>
  )
}

export default BlogPage
