import * as React from "react"
import Layout from "./components/layout"

// markup
const IndexPage = () => {
  return (
    <Layout title="Index">
      <h2>hi</h2>
    </Layout>
  )
}

export default IndexPage
