import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default function Layout({ children, title }) {
  return (
    <>
      <title>{title}</title>
      <div className="flex flex-col">
        <header className="flex items-center justify-between flex-shrink-0 p-6 bg-gray-800 font-roboto rounded m-2 shadow-lg">
          <div className="flex items-center flex-shrink-0 text-white mr-6 shadow-none font-semibold">
            <a href="/">Aaron Carson</a>
          </div>
          <div className="flex items-center flex-shrink-0 text-white right-align shadow-none">
            <a href="/blog" className="pr-5 shadow-none">Blog</a>
            <a aria-label="My GitHub" className="pr-5 shadow-none" href="https://github.com/caraar12345"><FontAwesomeIcon icon={faGithub} /></a>
            <a aria-label="My LinkedIn" className="pr-5 shadow-none" href="https://www.linkedin.com/in/aaron-carson/"><FontAwesomeIcon icon={faLinkedin} /></a>
            <a aria-label="My Twitter" className="shadow-none" href="https://twitter.com/caraar12345"><FontAwesomeIcon icon={faTwitter} /></a>
          </div>
        </header>
      </div>
      <div>
        {children}
      </div>
    </>
  )
}